import { all } from "redux-saga/effects";
import logoutSaga from "./logoutSaga";
import loginSaga from "./loginSaga";
import storageSaga from "./storageSaga";

import resetPasswordSaga from "./pwReset_saga";
import forgotPasswordSaga from "./forgotPW_saga";
import changePasswordSaga from "./changePW_saga";
import sendLeadFormSaga from "./leadForm_saga";

import bulkUploadSaga from "./bulkUpload_saga";
import createCustomerSaga from "./createCustomer_saga";
import editBasicInfoSaga from "./editBasicInfo_saga";
import getBasicInfoSaga from "./getBasicInfo_saga";
import createVendorSaga from "./createVendor_saga";
import getSystemUsersSaga from "./getSystemUsers_saga";
import createSystemUserSaga from "./createSystemUser_saga";
import getAuditLogsSaga,{ downloadAuditLogSaga } from "./getAuditLogs_saga";
import getReportLogsSaga, {
  getReportLogsExcelDownloadSaga,
  getReportLogsPDFDownloadSaga,
} from "./getReportLogs_saga";

// STATUSES
import toggleUserStatusSaga from "./status/toggleUserStatus_saga";
import deleteUserSaga from "./status/deleteUser_saga";

// STATISTICS
import getISWOrderStatsSaga from "./statistics/getISWOrderStats_saga";
import getISWProfileStatsSaga from "./statistics/getISWProfileStats_saga";
import getAllUsersSaga from "./getAllUsers_saga";

// company info and users
import getCustomerUsersSaga from "./getCustomerUsers_saga";
import getCustomerInfoSaga from "./getCustomerInfo_saga";
import getCustomersSaga from "./getCustomers_saga";
import getVendorsSaga from "./getVendors_saga";
import getFleetsSaga from "./getFleets_saga";
import getVendorInfoSaga from "./getVendorInfo_saga";
import getFleetInfoSaga from "./getFleetInfo_saga";
import getVendorUsersSaga from "./getVendorUsers_saga";
import getFleetUsersSaga from "./getFleetUsers_saga";

// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([
    getVendorInfoSaga(),
    getFleetInfoSaga(),
    getCustomerInfoSaga(),
    //
    getCustomerUsersSaga(),
    getVendorUsersSaga(),
    getFleetUsersSaga(),
    //
    getCustomersSaga(),
    getVendorsSaga(),
    getFleetsSaga(),

    //
    resetPasswordSaga(),
    storageSaga(),
    logoutSaga(),
    loginSaga(),
    forgotPasswordSaga(),
    changePasswordSaga(),
    createCustomerSaga(),
    bulkUploadSaga(),
    editBasicInfoSaga(),
    getBasicInfoSaga(),
    getAuditLogsSaga(),
    downloadAuditLogSaga(),
    createVendorSaga(),
    getSystemUsersSaga(),
    createSystemUserSaga(),
    getAllUsersSaga(),
    getReportLogsSaga(),
    getReportLogsExcelDownloadSaga(),
    getReportLogsPDFDownloadSaga(),
    sendLeadFormSaga(),
    
    // STATUS
    toggleUserStatusSaga(),
    deleteUserSaga(),

    // STATISTICS
    getISWOrderStatsSaga(),
    getISWProfileStatsSaga(),
  ]);
}
