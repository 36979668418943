import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Spin, Select, DatePicker, Button, Input, Modal, Checkbox } from "antd";
import { DownloadOutlined, SearchOutlined, CloseOutlined, PrinterOutlined, CalendarOutlined, FileOutlined } from "@ant-design/icons";
import { Actions } from "../redux-flow/actions/_index";
import Sidebar from "../reuse/sidebar";
import BaseComponent from "../reuse/baseComponent";
// import moment from "moment";
import { formatMoney } from "../reuse/currencyHelper";
import MenuBar from "../reuse/MenuBar";
import { Notify } from "../reuse/notify";
import { calcDate } from '../reuse/util';
import ReactToPrint from "react-to-print";
import { PropsContext } from "../reuse/PropsContext";

const { getReportLogsActions } = Actions;

const { Option } = Select;
const { RangePicker } = DatePicker;

const pageStyle = `
@media {
 body {
   zoom: 250%;
 }
 @page {
  size: A4 landscape;
  }
  .ant-modal-centered.ant-modal{
    position:fixed;
     top:0;
  }
}
`;

class Reports extends BaseComponent {
  state = {
    currentPage: 1,
    dataPerPage: 10,
    SearchKeyword: "",
    moduleType: [],
    actionType: [],
    searchCategory: "",
    deliveryStartDate: null,
    deliveryEndDate: null,
    selectedOrder: {},
    downloadOption: 'pdf',
    filterOptions: [{ value: "customerName", label: 'Customer Name' },
    { value: "deliveryCompany", label: 'Delivery Company' },
    { value: "productName", label: 'Product Name' },
    { value: 'vendorName', label: 'Vendor Name' }],

    pageConfig: {
      page: 1,
      pageSize: 10,
    },
    isPrinting: false,
  };

  componentDidMount() {
    //const { currentPage: pageIndex, dataPerPage: pageSize } = this.state;
    this.fetch();
  }

  fetch = () => {
    const { pageConfig, currentPage } = this.state;
    this.setState({ pageConfig: { ...pageConfig, page: currentPage } }, () => {
      this.props.dispatch(getReportLogsActions.getReportLogs(pageConfig));
    })
  };

  handleClearselect = (open) => {
    if (open) {
      let _state = this.state;
      delete _state.filterOption;
      this.setState(_state);
    }
  }


  handleSearch = ({ target: { value } }) => {
    const { pageConfig, filterOptions } = this.state;
    let _pageConfig = pageConfig;
    this.setState({ SearchKeyword: value });
    if (value === '') {
      filterOptions.forEach(item => {
        if (pageConfig.hasOwnProperty(item.value)) {
          //delete filterOption so placeholder can show
          let _state = this.state;
          delete _state.filterOption;
          this.setState(_state);
          delete _pageConfig[item.value];
          this.setState({ pageConfig: _pageConfig, SearchKeyword: '' }, () => {
            this.handleSelectChange('');
          })
          return;
        }
      });
    }
  };

  handleTableChange = (page, pageSize) => {
    // const { dataPerPage, SearchKeyword, action, module } = this.state;
    const { pageConfig } = this.state;
    this.setState({ pageConfig: { ...pageConfig, page: page }, currentPage: page }, () => {
      this.fetch();
    })

  };

  getBySearch = (e, empty) => {
    e.preventDefault();
    const { /* dataPerPage: pageSize, */ SearchKeyword } = this.state;
    this.setState({
      currentPage: 1,
      SearchKeyword: empty ? "" : SearchKeyword,
    });
    // this.props.dispatch(
    //   Actions.getAuditLogs({
    //     pageIndex: 1,
    //     pageSize,
    //     filterBy: empty ? "" : SearchKeyword,
    //   })
    // );
  };

  handleSelectChange = (value) => {
    let _state = this.state;
    const { pageConfig, SearchKeyword } = this.state;
    if (value === "reset") {
      delete _state.filterOption;
      this.setState(_state);
      this.setState({
        SearchKeyword: "",
      });
      return;
    }

    if (SearchKeyword === "") {
      Notify({
        message: "No filter parameter",
        className: "error",
      });
      return;
    }

    if (value) {
      this.setState({ filterOption: value });
    }

    this.setState({ pageConfig: { ...pageConfig, [value]: SearchKeyword } }, () => {
      this.fetch();
    });

  };

  handleDateChange = (date, dateString) => {
    // refetch when date is cleared
    const { pageConfig } = this.state;
    if (date === null) return;
    let _pageConfig = pageConfig;
    if (!dateString[0] || !dateString[1]) {
      //clear date from state object
      delete _pageConfig.deliveryStartDate;
      delete _pageConfig.deliveryEndDate;
    }
    if (dateString[0] || dateString[1]) {
      _pageConfig = {
        ..._pageConfig,
        deliveryStartDate: dateString[0],
        deliveryEndDate: dateString[1]
      }
    }
    this.setState({ pageConfig: { ..._pageConfig, page: 1 }, currentPage: 1 }, () => {
      this.fetch();
    })
    // let _date = moment(dateString).format();
  };

  handlePDFDownload = () => {
    const { pageConfig } = this.state;
    this.props.dispatch(
      getReportLogsActions.getReportLogsPDFDownloads(pageConfig)
    );
    this.toggleDownloadModal();
  };

  handleExcelDownload = () => {
    const { pageConfig } = this.state;
    this.props.dispatch(
      getReportLogsActions.getReportLogsExcelDownloads(pageConfig)
    );
    this.toggleDownloadModal();
  };

  openRowdetails = (order) => {
    this.setState({ selectedOrder: order }, () => {
      this.setState({ showModal: !this.state.showModal })
    })
  }

  toggelModal = () => {
    this.setState({ showModal: !this.state.showModal });
  }

  toggleDownloadModal = () => {
    this.setState({ showDownloadModal: !this.state.showDownloadModal });
  }

  handleDownload = () => {
    const { downloadOption } = this.state;
    if (downloadOption === 'pdf') {
      this.handlePDFDownload();
    }

    if (downloadOption === 'xlsx') {
      this.handleExcelDownload();
    }
  }

  toggleDownloadOptions = (e, val) => {
    if (e.target.checked) {
      this.setState({ downloadOption: val });
    }
  }

  handleOnBeforeGetContent = () => {
    this.setState({ isPrinting: true });
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 100);
    });
  };

  handleAfterPrint = () => {
    this.setState({ isPrinting: false });
  }

  renderDetailModal = () => {
    const { showModal, selectedOrder, isPrinting } = this.state;
    return (
      <Modal
        closeIcon={<CloseOutlined className="print-hide-button" style={{ fontSize: 25, marginRight: 70, marginTop: 30, color: '#000000' }} />}
        className="report-modal"
        width={isPrinting? '100%': '50%'}
        centered
        title="Order Details"
        visible={showModal}
        onOk={this.toggelModal}
        onCancel={this.toggelModal}
        bodyStyle={{
          // maxHeight: 400,
          // height: 500,
          overflowY: 'scroll'
        }}
        ref={(el) => (this.componentRef = el)}
        footer={[
          <Button style={{ width: 130, color: '#5F738C', borderColor: '#7F91A8' }} key="back" className="print-hide-button" size="large" onClick={this.toggelModal}>
            Cancel
          </Button>,
          <Fragment>
            <ReactToPrint
              trigger={() => {
                return (<Button style={{ width: 130 }} key="submit" type="primary" className="report-btn print-hide-button" size="large">
                  <PrinterOutlined style={{ fontSize: 20, verticalAlign: 'bottom' }} />
                  Print
                </Button>)
              }}
              content={() => this.componentRef}
              onBeforeGetContent={this.handleOnBeforeGetContent}
              onAfterPrint={this.handleAfterPrint}
              pageStyle={pageStyle}
            />
          </Fragment>,
        ]}
      >
        <div className="row mr-5 ml-5 pb-0" >
          {/* First row */}
          <div className="col-12 detail-row mt-3">
            <div className="row full-width ft-14">
              <div className="col-4 col-lg-4 col-sm-6 p-0 m-0">
                <label className="d-block column-header">Customer</label>
                <label className="column-body ">{selectedOrder.customerName}</label>
              </div>
              <div className="col-4 col-lg-4 col-sm-6 p-0 m-0">
                <label className="d-block ft-14 column-header">Location</label>
                <label className="column-body">{selectedOrder.locationName}</label>
              </div>
              <div className="col-4 col-lg-4 col-sm-6 p-0 m-0">
                <label className="d-block ft-14 column-header">Product</label>
                <label className="column-body">{selectedOrder.product}</label>
              </div>
            </div>
          </div>
          {/* second row */}
          <div className="col-12 detail-row mt-4 pb-3">
            <div className="row full-width">
              <div className="col-4 col-lg-4 col-sm-6 p-0 m-0 text-left">
                <label className="d-block ft-14 column-header">Quantity Requested</label>
                <label className="column-body">{selectedOrder.quantityRequested}</label>
              </div>
              <div className="col-4 col-lg-4 col-sm-6 p-0 m-0">
                <label className="d-block ft-14 column-header">Amount</label>
                <label className="column-body">{formatMoney(selectedOrder.orderAmount)}</label>
              </div>
              <div className="col-4 col-lg-4 col-sm-6 p-0 m-0">
                <label className="d-block ft-14 column-header">Vendor</label>
                <label className="column-body">{selectedOrder.vendor}</label>
              </div>

              {/* <div className="col-4 col-lg-4 col-sm-6 p-0 m-0">
              <label className="d-block ft-14 column-header">Delivery Date</label>
                <label className="column-body">{calcDate(new Date(selectedOrder.deliveryDate))}</label>
              </div> */}
            </div>
          </div>
          {/* third row */}
          <div className="col-12 detail-row mt-4 pb-3">
            <div className="row full-width">
              <div className="col-4 col-lg-4 col-sm-6 p-0 m-0 text-left">
                <label className="d-block ft-14 column-header">Driver's Name</label>
                <label className="column-body">{selectedOrder.driverName}</label>
              </div>
              <div className="col-4 col-lg-4 col-sm-6 p-0 m-0">
                <label className="d-block ft-14 column-header">Delivery Company</label>
                <label className="column-body">{selectedOrder.deliveryCompany}</label>
              </div>
              <div className="col-4 col-lg-4 col-sm-6 p-0 m-0">
                <label className="d-block ft-14 column-header">Quantity Delivered</label>
                <label className="column-body">{selectedOrder.quantityDelivered}</label>
              </div>
            </div>
          </div>

          {/* third row */}
          <div className="col-12 detail-row mt-4 pb-3">
            <div className="row full-width">
              <div className="col-4 col-lg-4 col-sm-6 p-0 m-0 text-left">
                <label className="d-block ft-14 column-header">Order Id</label>
                <label className="column-body">{selectedOrder.quantityRequested}</label>
              </div>
              <div className="col-4 col-lg-4 col-sm-6 p-0 m-0">
                <label className="d-block ft-14 column-header">Delivery Date</label>
                {selectedOrder.deliveryDate && <label className="column-body">{calcDate(new Date(selectedOrder.deliveryDate))}</label>}
              </div>
              <div className="col-4 col-lg-4 col-sm-6 p-0 m-0">
                <label className="d-block ft-14 column-header">Payment Status</label>
                <label className="column-body">{selectedOrder.paymentStatus}</label>
              </div>
            </div>
          </div>

          {/* third row */}
          <div className="col-12 detail-row no-border mt-4 pb-3">
            <div className="row full-width">
              <div className="col-4 col-lg-4 col-sm-6 p-0 m-0 text-left">
                <label className="d-block ft-14 column-header">Payment Reference</label>
                <label className="column-body">{selectedOrder.paymentReference}</label>
              </div>
              <div className="col-4 col-lg-4 col-sm-6 p-0 m-0">
                <label className="d-block ft-14 column-header">Payment Date</label>
                {selectedOrder.paymentDate && <label className="column-body">{calcDate(new Date(selectedOrder.paymentDate))}</label>}
              </div>
              <div className="col-4 col-lg-4 col-sm-6 p-0 m-0">

              </div>
            </div>
          </div>
        </div>
      </Modal>)
  }

  renderDownloadModal = () => {
    const { showDownloadModal, downloadOption, pageConfig: { deliveryStartDate, deliveryEndDate } } = this.state;
    return (
      <Modal
        closeIcon={<CloseOutlined style={{ fontSize: 20, marginRight: 70, marginTop: 30, color: '#000000' }} />}
        className="report-modal download"
        // width={'50%'}
        centered
        title="Download reports"
        visible={showDownloadModal}
        onOk={this.toggleDownloadModal}
        onCancel={this.toggleDownloadModal}

        footer={[
          <Button style={{ width: 130, color: '#5F738C', borderColor: '#7F91A8' }} key="back" size="large"  onClick={this.toggleDownloadModal}>
            Cancel
          </Button>,
          <Button onClick={this.handleDownload} style={{ width: 130 }} key="submit" type="primary" className="report-btn" size="large">
            <DownloadOutlined style={{ fontSize: 20, verticalAlign: 'bottom' }} />
            Download
          </Button>
        ]}>
        <div>
          {(deliveryEndDate && deliveryStartDate) &&
            <div style={{
              width: 292
            }}>
              <div className="pb-0 mb-0"><label className="pb-0 mb-0 ft-12" style={{
                color: '#7F91A8'
              }}>From</label><label className="pb-0 mb-0 ft-12" style={{
                color: '#7F91A8',
                marginLeft: '50%'
              }}>To</label></div>
              <div className="d-flex date-display">
                <span className="pt-2">
                  <CalendarOutlined style={{ fontSize: 20, verticalAlign: 'bottom' }} />
                </span>
                <span className="center-date pt-2">
                  {calcDate(deliveryStartDate)}
                </span>
                <span className="pt-2">
                  {calcDate(deliveryEndDate)}
                </span>
              </div>
            </div>
          }
          <div className="mt-3">
            <label className="ft-16 ft-w600 d-block">Choose your preferred file format</label>
            <span><Checkbox checked={downloadOption === 'xlsx' ? true : false} onChange={(e) => this.toggleDownloadOptions(e, 'xlsx')}>xlsx</Checkbox></span>
            <span style={{
              display: 'inline-block',
              marginLeft: 55,
            }}><Checkbox checked={downloadOption === 'pdf' ? true : false} onChange={(e) => this.toggleDownloadOptions(e, 'pdf')}>pdf</Checkbox></span>
          </div>
          <div className="mt-3 mb-4 p-3" style={{
            height: 58,
            background: '#F2F3F5',
            borderRadius: 6,
            color: '#096DD9',
            width: '100%'
          }}>
            <FileOutlined style={{
              color: '#096DD9', fontSize: 30
            }} />
            <label className="ft-14 pl-5">report.{downloadOption}</label>
          </div>
        </div>
      </Modal>)
  }

  render() {
    const { SearchKeyword, filterOptions, filterOption } = this.state;
    const {
      total,
      reports,
      // loading,
      // noOfPages,
      // sizeOfCurrentPage,
    } = this.props;
    const { loading, status, toggleModal } = this.context;
    return (
      <Fragment>
        <div className="isw-mainLayout1">
          <Sidebar
            search={this.state.SearchKeyword}
            status={status}
            toggleModal={toggleModal}
            resetSearch={this.getBySearch}
          />
          <div />
          <Spin tip="Please wait..." spinning={loading}>
            <div className="isw-content--wrapper">
              <MenuBar title="Reports" />
              <div>
                <div className="content" id="content-body">
                  <div className="container-fluid container-limited">
                    {/* <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb bg-transparent px-0">
                                                <li className="breadcrumb-item">
                                                    <span
                                                        style={{
                                                            color: "#00425f"
                                                        }}>
                                                        Audit
                                                    </span>
                                                </li>
                                            </ol>
                                        </nav> */}

                    <div className="row mb-4 mt-3">
                      <div className="col-lg-12">
                        <div className="row mb-3">
                          <div className="col-lg-4 isw-valign--middle">
                            {this.renderPageTracker(total)}
                          </div>

                          <div className="col-lg-3 ml-0 d-flex justify-content-end">
                            <RangePicker
                              size="large"
                              className=""
                              placeholder="Delivery Date"
                              onChange={this.handleDateChange}
                            />
                          </div>

                          <form
                            className="mt-md-0 col-lg-5 ml-0 ml-md-auto"
                            onSubmit={this.getBySearch}
                          >
                            <Input
                              prefix={<SearchOutlined style={{ color: '#7F91A8', fontSize: '15px' }} />}
                              allowClear
                              value={SearchKeyword}
                              onChange={this.handleSearch}
                              onBlur={this.handleSearch}
                              className="filter-input"
                              placeholder="Filter..."
                              size="large"
                              style={{
                                paddingLeft: 5,
                                fontSize: 14,
                                color: '#4B4B4B',
                              }}
                            />
                            <Select
                              size="large"
                              className="select-height"
                              placeholder="Select Category"
                              style={{ width: "50%", height: "37px" }}
                              onChange={this.handleSelectChange}
                              onDropdownVisibleChange={this.handleClearselect}
                              value={filterOption}
                            >
                              {filterOptions.map((filter, index) => {
                                return (<Option key={`${filter.value}-${index}`} value={filter.value}>
                                  {filter.label}
                                </Option>)
                              })}
                            </Select>
                          </form>
                        </div>
                        <div className="row mb-4">
                          <div className="col-12 mt-1">
                            {/* <Button
                              onClick={this.handleExcelDownload}
                              className="mr-1"
                            >
                              Download Reports as Excel
                            </Button>
                            <Button onClick={this.handlePDFDownload}>
                              Download Reports as PDF
                            </Button> */}
                            <Button onClick={this.toggleDownloadModal} className="report-btn download-btn" type="primary" size="large">
                              <DownloadOutlined style={{ fontSize: 20, verticalAlign: 'bottom' }} />
                              Download Report
                            </Button>
                          </div>
                        </div>
                        <div className="row mb-4 mt-3">
                          <div className="col-12">
                            <div className="table-container pb-4">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th className="pl-3">Customer</th>
                                    <th>Location</th>
                                    <th>Product</th>
                                    <th>Quantity Requested</th>
                                    <th>Amount</th>
                                    <th>Vendor</th>
                                    <th>Delivery Company</th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {reports.length !== 0
                                    ? reports.map((order, index) => {
                                      return (<tr key={index}>
                                        <td className="pl-3">{order.customerName}</td>
                                        <td>{order.locationName}</td>
                                        <td>{order.product}</td>
                                        <td>{order.quantityRequested}</td>
                                        <td>{formatMoney(order.orderAmount)}</td>
                                        <td>{order.vendor}</td>
                                        <td>{order.deliveryCompany}</td>
                                        <th><Button type="link"
                                          onClick={() => { this.openRowdetails(order) }}
                                          style={{
                                            color: '#096DD9',
                                            fontWeight: '500'
                                          }}>View</Button></th>
                                      </tr>)
                                    }) : (<td colSpan={8}>{this.renderTableAbnormality(`No reports`)}</td>)}

                                </tbody>
                              </table>
                              {this.renderPagination(total)}
                            </div>
                          </div>
                        </div>

                        {/* <div className="isw-table mb-3">
                          <ul className="isw-table--head d-none d-md-block">
                            <li className="isw-table--headLi">
                              <div className="row">
                                <div className="col text-center">
                                  <span className="isw-table--headItem">
                                    Customer Name
                                  </span>
                                </div>
                                <div className="col text-center">
                                  <span className="isw-table--headItem">
                                    Location
                                  </span>
                                </div>
                                <div className="col text-center">
                                  <span className="isw-table--headItem">
                                    Delivery company
                                  </span>
                                </div>
                                <div className="col text-center">
                                  <span className="isw-table--headItem">
                                    Vendor
                                  </span>
                                </div>
                                <div className="col text-center">
                                  <span className="isw-table--headItem">
                                    Product
                                  </span>
                                </div>
                                <div className="col text-center">
                                  <span className="isw-table--headItem">
                                    Amount
                                  </span>
                                </div>
                                <div className="col text-center">
                                  <span className="isw-table--headItem text-center">
                                    Quantity Requested
                                  </span>
                                </div>
                                <div className="col text-center">
                                  <span className="isw-table--headItem">
                                    Order ID
                                  </span>
                                </div>
                                <div className="col text-center">
                                  <span className="isw-table--headItem text-center">
                                    Quantity Delivered
                                  </span>
                                </div>
                                <div className="col text-center">
                                  <span className="isw-table--headItem">
                                    Delivery Date
                                  </span>
                                </div>
                                <div className="col text-center">
                                  <span className="isw-table--headItem">
                                    Payment Date <br />
                                    Reference
                                  </span>
                                </div>
                                <div className="col text-center">
                                  <span className="isw-table--headItem">
                                    Payment Status
                                  </span>
                                </div>
                              </div>
                            </li>
                          </ul>
                          {total === 0 ? (
                            this.renderTableAbnormality(`No reports`)
                          ) : (
                           
                            <ul className="isw-table--body">
                              {reports.map((report, index) => {
                                const {
                                  customerName,
                                  vendor,
                                  locationName,
                                  deliveryCompany,
                                  product,
                                  quantityRequested,
                                  quantityDelivered,
                                  deliveryDate,
                                  amount,
                                  paymentDate,
                                  paymentReference,
                                  paymentStatus,
                                  paymentMode,
                                  orderId
                                } = report;

                                return (
                                  <li className="isw-table--bodyLi" key={index}>
                                    <span className="row isw-table--bodyA">
                                      <div className="col isw-valign--middle">
                                        <span className="isw-p2">
                                          {customerName}
                                        </span>
                                      </div>

                                      <div className="col isw-valign--middle">
                                        <span className="isw-p2">
                                          {locationName}
                                        </span>
                                      </div>
                                      <div className="col isw-valign--middle">
                                        <span className="isw-p2">
                                          {deliveryCompany}
                                        </span>
                                      </div>
                                      <div className="col isw-valign--middle">
                                        <span className="isw-p2">{vendor}</span>
                                      </div>
                                      <div className="col isw-valign--middle">
                                        <span className="isw-p2">
                                          {product}
                                        </span>
                                      </div>

                                      <div className="col isw-valign--middle">
                                        <span className="isw-p2">{amount}</span>
                                      </div>
                                      <div className="col isw-valign--middle">
                                        <span className="isw-p2 text-center">
                                          {quantityRequested}
                                        </span>
                                      </div>
                                      <div className="col isw-valign--middle">
                                        <span className="isw-p2">
                                          {orderId}
                                        </span>
                                      </div>
                                      <div className="col isw-valign--middle">
                                        <span className="isw-p2 text-center">
                                          {quantityDelivered}
                                        </span>
                                      </div>
                                      <div className="col isw-valign--middle">
                                        <span className="isw-p2">
                                          {new Date(
                                            deliveryDate
                                          ).toLocaleDateString()}
                                        </span>
                                      </div>
                                      <div className="col isw-valign--middle">
                                        <span className="isw-p2">
                                          {paymentReference} <br />
                                          {new Date(
                                            paymentDate
                                          ).toLocaleDateString()}
                                        </span>
                                      </div>
                                      <div className="col isw-valign--middle no-border">
                                        {paymentMode === "4" && <span className="text-center isw-p2">
                                          {paymentStatus === "1" ? "Paid" : (paymentStatus === "2" ? "Pending" : (paymentStatus === "3" ? "Failed" : ""))}
                                        </span>}
                                      </div>

                                      
                                    </span>
                                  </li>
                                );
                              })}
                            </ul>
                          )}
                        </div> */}
                        {/* {this.renderPagination(total)} */}
                        {this.renderDetailModal()}
                        {this.renderDownloadModal()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Spin>
        </div>
      </Fragment>
    );
  }
}
Reports.contextType = PropsContext;
const mapStateToProps = ({ getReportLogs_reducer }) => {
  const {
    reports,
    total,
    noOfPages,
    sizeOfCurrentPage,
  } = getReportLogs_reducer;
  return {
    reports,
    total,
    noOfPages,
    sizeOfCurrentPage,
  };
};

export default connect(mapStateToProps)(Reports);
