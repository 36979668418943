/*
this houses every variable used in
- Actions

- Reducers

- Sagas

thus `${ars}Variables` 😄
*/

// GENERIC variables

export const baseurl =
process.env.REACT_APP_BASE_AUTH_URL || "https://feulapp.azurewebsites.net/api/";
export const LH = "localhost:3000/";
export const SUCCESS = "SUCCESS";
export const FAILURE = "FAILURE";
export const RESET = "RESET";
export const API_KEY = "AIzaSyDeuD95hbs39ZsZPLS0vICN4tbezVCK0Kg";
export const EXPIRY = "EXPIRY";
export const ENCRYPT_USER = "USER";
export const SUPER_ADMIN = "SUPER_ADMIN";
export const VENDOR_ADMIN = "VENDOR_ADMIN";
export const CUSTOMER_ADMIN = "CUSTOMER_ADMIN";
export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
export const TOGGLE_RIGHT_SIDEBAR = "TOGGLE_RIGHT_SIDEBAR";

export const GET_ALL_USERS_FAILURE = "GET_ALL_USERS_FAILURE";
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const GET_ALL_USERS = "GET_ALL_USERS";

// login variables
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGIN_USER = "LOGIN_USER";

// logout variables
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";
export const LOGOUT_USER = "LOGOUT_USER";

// storage variables
export const READ_SUCCESS = "READ_SUCCESS";
export const READ_FAILURE = "READ_FAILURE";
export const READ_STORAGE = "READ_STORAGE";
export const READ_EXPIRED = "READ_EXPIRED";
export const REFRESH_TOKEN = "REFRESH_TOKEN";

// reset variables
export const PW_RESET = "PW_RESET";
export const PW_SUCCESS = "PW_RESET_SUCCESS";
export const PW_FAILURE = "PW_RESET_FAILURE";

// forgotPassword variables
export const FORGOT_RESET = "FORGOT_RESET";

// change password variables
export const CHANGE_PW = "CHANGE_PW";

// bulk upload customer user variables
export const BULK_UPLOAD = "BULK_UPLOAD";

// create customer  variables
export const CREATE_CUSTOMER = "CREATE_CUSTOMER";

// create vendor variables
export const CREATE_VENDOR = "CREATE_VENDOR";

// edit profile basic info variables
export const GET_BASIC_INFO = "GET_BASIC_INFO";
export const GET_BASIC_SUCCESS = "GET_BASIC_SUCCESS";
export const GET_BASIC_FAILURE = "GET_BASIC_FAILURE";

// edit profile basic info variables
export const EDIT_BASIC_INFO = "EDIT_BASIC_INFO";
export const EDIT_BASIC_SUCCESS = "EDIT_BASIC_SUCCESS";
export const EDIT_BASIC_FAILURE = "EDIT_BASIC_FAILURE";

// get system users variables
export const GET_SYSTEM_USERS = "GET_SYSTEM_USERS";
export const GET_SYSTEM_USERS_SUCCESS = "GET_SYSTEM_USERS_SUCCESS";
export const GET_SYSTEM_USERS_FAILURE = "GET_SYSTEM_USERS_FAILURE";

// create system users variables
export const CREATE_SYSTEM_USER = "CREATE_SYSTEM_USER";

// get users on the app variables
export const GET_USERS = "GET_USERS";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAILURE = "GET_USERS_FAILURE";

// toggle user status variables
export const TOGGLE_USER_STATUS = "TOGGLE_USER_STATUS";

// DELETE user variables
export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";

// statistics
export const GET_ISW_ORDER_STATS = "GET_ISW_ORDER_STATISTICS";
export const GET_ISW_ORDER_STATS_FAILURE = "GET_ISW_ORDER_STATISTICS_FAILURE";
export const GET_ISW_ORDER_STATS_SUCCESS = "GET_ISW_ORDER_STATISTICS_SUCCESS";

export const GET_ISW_PROFILE_STATS = "GET_ISW_PROFILE_STATISTICS";
export const GET_ISW_PROFILE_STATS_FAILURE =
    "GET_ISW_PROFILE_STATISTICS_FAILURE";
export const GET_ISW_PROFILE_STATS_SUCCESS =
    "GET_ISW_PROFILE_STATISTICS_SUCCESS";

// audit logs
export const GET_AUDIT_LOGS_FAILURE = "GET_AUDIT_LOGS_FAILURE";
export const GET_AUDIT_LOGS_SUCCESS = "GET_AUDIT_LOGS_SUCCESS";
export const GET_AUDIT_LOGS = "GET_AUDIT_LOGS";
export const DOWNLOAD_AUDIT_LOGS = "DOWNLOAD_AUDIT_LOGS";
export const DOWNLOAD_AUDIT_LOGS_SUCCESS = "DOWNLOAD_AUDIT_LOGS_SUCCESS";
export const DOWNLOAD_AUDIT_LOGS_FAILURE = "DOWNLOAD_AUDIT_LOGS_SUCCESS_FAILURE";

// customer info
export const GET_CUSTOMER_INFO_FAILURE = "GET_CUSTOMER_INFO_FAILURE";
export const GET_CUSTOMER_INFO_SUCCESS = "GET_CUSTOMER_INFO_SUCCESS";
export const GET_CUSTOMER_INFO = "GET_CUSTOMER_INFO";

// customer users
export const GET_CUSTOMER_USERS_FAILURE = "GET_CUSTOMER_USERS_FAILURE";
export const GET_CUSTOMER_USERS_SUCCESS = "GET_CUSTOMER_USERS_SUCCESS";
export const GET_CUSTOMER_USERS = "GET_CUSTOMER_USERS";

// vendor info
export const GET_VENDOR_INFO_FAILURE = "GET_VENDOR_INFO_FAILURE";
export const GET_VENDOR_INFO_SUCCESS = "GET_VENDOR_INFO_SUCCESS";
export const GET_VENDOR_INFO = "GET_VENDOR_INFO";

// vendor users
export const GET_VENDOR_USERS_FAILURE = "GET_VENDOR_USERS_FAILURE";
export const GET_VENDOR_USERS_SUCCESS = "GET_VENDOR_USERS_SUCCESS";
export const GET_VENDOR_USERS = "GET_VENDOR_USERS";

// fleet info
export const GET_FLEET_INFO_FAILURE = "GET_T_FLEET_INFO_FAILURE";
export const GET_FLEET_INFO_SUCCESS = "GET_T_FLEET_INFO_SUCCESS";
export const GET_FLEET_INFO = "GET_T_FLEET_INFO";

// fleet users
export const GET_FLEET_USERS_FAILURE = "GET_FLEET_USERS_FAILURE";
export const GET_FLEET_USERS_SUCCESS = "GET_FLEET_USERS_SUCCESS";
export const GET_FLEET_USERS = "GET_FLEET_USERS";

export const GET_VENDORS_FAILURE = "GET_VENDORS_FAILURE";
export const GET_VENDORS_SUCCESS = "GET_VENDORS_SUCCESS";
export const GET_VENDORS = "GET_VENDORS";

export const GET_CUSTOMERS_FAILURE = "GET_CUSTOMERS_FAILURE";
export const GET_CUSTOMERS_SUCCESS = "GET_CUSTOMERS_SUCCESS";
export const GET_CUSTOMERS = "GET_CUSTOMERS";

export const GET_FLEETS_FAILURE = "GET_FLEETS_FAILURE";
export const GET_FLEETS_SUCCESS = "GET_FLEETS_SUCCESS";
export const GET_FLEETS = "GET_FLEETS";

// Reports 
export const GET_REPORT_LOGS_FAILURE = "GET_REPORT_LOGS_FAILURE";
export const GET_REPORT_LOGS_SUCCESS = "GET_REPORT_LOGS_SUCCESS";
export const GET_REPORT_LOGS = "GET_REPORT_LOGS";
export const GET_REPORT_LOGS_PDF_DOWNLOAD = "GET_REPORT_LOGS_PDF_DOWNLOAD";
export const GET_REPORT_LOGS_EXCEL_DOWNLOAD = "GET_REPORT_LOGS_EXCEL_DOWNLOAD";
export const GET_REPORT_LOGS_DOWNLOAD_SUCCESS = "GET_REPORT_LOGS_DOWNLOAD_SUCCESS";
export const GET_REPORT_LOGS_DOWNLOAD_FAILURE = "GET_REPORT_LOGS_DOWNLOAD_FAILURE";

export const SEND_LEAD_FORM_SUCCESS="SEND_LEAD_FORM_SUCCESS";
export const SEND_LEAD_FORM_FAILURE="SEND_LEAD_FORM_FAILURE";
export const SEND_LEAD_FORM="SEND_LEAD_FORM";
export const CLEAR_LEAD_FORM="CLEAR_LEAD_FORM";
