
import { decryptAndRead } from "./localStorageHelper";
import { history } from "../../reuse/history";
import { ENCRYPT_USER, EXPIRY } from "../arsVariables";
import  store  from "../../store-util";
import { Actions } from "../actions/_index";
import { url } from "../../route";
export const axios = require("axios");

axios.interceptors.response.use(
  (response) => {
    // Do something with response data
    return response;
  },
  (error) => {
    if (error.response) {
      const { status, data } = error.response;
      if (status === 401 && data === "") {
        const decryptedToken = decryptAndRead(ENCRYPT_USER);
        const expiry = localStorage.getItem(EXPIRY);

        if (!decryptedToken) {
          store.dispatch(Actions.logoutUser());
        } else if (decryptedToken) {
          const { access_token, expired } = decryptedToken;
          if (expired && new Date().getTime() < Number(expiry) + 3600000) {
            store.dispatch(Actions.onInit());
          } else if (
            expired &&
            new Date().getTime() > Number(expiry) + 3600000
          ) {
            store.dispatch(Actions.logoutUser());
          }

          const retryOriginalRequest = new Promise((resolve) => {
            error.config.headers.Authorization = "Bearer " + access_token;
            resolve(axios(error.config));
          });

          return retryOriginalRequest;
        }
      }
      if (status >= 500) {
        if (!window.location.pathname.includes("dashboard")) {
          setTimeout(() => {
            history.push({ pathname: `${url}#/500` });
          }, 1000);
        }
        return Promise.reject({ error, message: "Server error" });
      }
    }
    return Promise.reject(error);
  }
);

axios.interceptors.request.use(
  (config) => {
    config.headers["Content-Type"] = "application/json";
    const decryptedToken = decryptAndRead(ENCRYPT_USER);
    if (decryptedToken) {
      const { access_token, expired } = decryptedToken;
      if (!expired) {
        config.headers.Authorization = `Bearer ${access_token}`;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject({ error });
  }
);

/**
 * WHY? To Download files
  You create an axios instance for downloads separately,
 because you need the "responseType"
 */

const downloadInstance = axios.create({
  responseType: "blob", // VERY IMPORTANT FOR DOWNLOADS else file will be blank
});
// intercept to add auth headers
downloadInstance.interceptors.request.use(
  (config) => {
    config.headers["Content-Type"] = "application/json";
    const decryptedToken = decryptAndRead(ENCRYPT_USER);
    if (decryptedToken) {
      const { access_token, expired } = decryptedToken;
      if (!expired) {
        config.headers.Authorization = `Bearer ${access_token}`;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject({ error });
  }
);

const getFuncForDownload = (path, payload) => {
  return new Promise((resolve, reject) => {
    downloadInstance
      .get(path, payload)
      .then((res) => {
        return resolve({ ...res, error: false });
      })
      .catch((error) => {
        return reject({ error: true, response: error });
      });
  });
};

const getFunc = (path, payload) => {
  return new Promise((resolve, reject) => {
    axios
      .get(path, payload)
      .then((res) => {
        return resolve({ ...res, error: false });
      })
      .catch((error) => {
        return reject({ error: true, response: error });
      });
  });
};

const delFunc = (path) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(path)
      .then((res) => {
        return resolve({ ...res, error: false });
      })
      .catch((error) => {
        return reject({ error: true, response: error });
      });
  });
};

const postFunc = (path, payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(path, payload)
      .then((res) => {
        return resolve({ ...res, error: false });
      })
      .catch((error) => {
        return reject({ error: true, response: error });
      });
  });
};

export { getFunc, delFunc, postFunc, getFuncForDownload };
