import React, { Fragment} from "react";
import { Navigate } from "react-router-dom";
import { instanceOf } from "prop-types";
import { connect } from "react-redux";
import { Cookies, withCookies } from "react-cookie";
import { Actions } from "../../redux-flow/actions/_index";
import { SUCCESS, FAILURE } from "../../redux-flow/arsVariables";
import { history } from "../../reuse/history";
import FormLayout from "../../reuse/form";
import { cookieName, isCompliant } from "../../reuse/Ndpr";
import Joi from "joi-browser";
import "../../assets/stylesheet/Style.css";
import Header from "../../components/Header/Header";
import { Footer } from "../../reuse/footer";
import { EyeOpenSVG, EyeClosedSVG } from "../../assets/Svgs/Svgs";
import { forgotPasswordUrl } from "../../redux-flow/services/authServices";
import ROUTE_PATHS from "../../route";
import { SelectInput } from "../../refresh/component/Input/input";

class Login extends FormLayout {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  state = {
    canLogin: false,
    redirectToReferrer: false,
    data: {
      email: "",
      password: "",
    },
    errors: {},
    styles: {},
    actions: {
      isLoggingIn: false,
      isShowPassword: false,
      passwordResetSuccess: false,
    },
    companyList: [{ label: "Abiot Associates Ltd (Nipco)", value: 1},
                  { label: "Cocean Oil", value: 2},
                  { label: "Sobaz Oil", value: 3},
                  { label: "OVH Energy Marketing Ltd", value: 4}],
    urlList: { 1 : "https://portal.fuelfact.com/login",
               2 : "https://portal.fuelfact.com/login",
               3 : "https://interswitchenergy.epump.com.ng/",
               4 : "https://interswitchenergy.epump.com.ng/" },
    companyLink: "",
   };

  schema = {
    email: Joi.string()
      .regex(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "email"
      )
      .required()
      .label("Email Address"),
    password: Joi.string()
      .required()
      .label("Password"),
  };

  static getDerivedStateFromProps = (props, state) => {
    const { login, dispatch, loginErrorMessage, fakeAuth, modules = [] } = props;
    // if login is successful
    if (login === SUCCESS && modules) {
      fakeAuth.authenticate();
      if ((history.location && (history.location.pathname === ROUTE_PATHS["quick-energy-signin"]) && !modules.includes("QUICKTELLER_FUEL")) ||
        (history.location && (history.location.pathname === ROUTE_PATHS["contract-management"]) && !modules.includes("CONTRACT_MANAGEMENT"))) {
        dispatch(Actions.logoutUser());
        console.log('what to return');
        return {
          errors: { ...state.errors, others: "You do not have access, invalid credentials" },
          redirectToReferrer: false,
          actions: { ...state.actions, isLoggingIn: false },
        };
      }
      if (history.location.pathname !== "/logout") {
        localStorage.setItem("loginPath", history.location.pathname);
      }
      return {
        redirectToReferrer: true,
      };
    }

    // if login fails
    if (login === FAILURE) {
      dispatch(Actions.reset());
      return {
        errors: { ...state.errors, others: loginErrorMessage },
        redirectToReferrer: false,
        actions: { ...state.actions, isLoggingIn: false },
      };
    }
    return null;
  };

  onLogin = (e) => {
    e.preventDefault();
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;

    const { email, password } = this.state.data;
    this.setState({ actions: { ...this.state.actions, isLoggingIn: true } });
    this.props.dispatch(Actions.loginUser({ email, password }));
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ data: { ...this.state.data, [name]: value } });
  };

  dismissError = () => {
    this.setState({ ...this.state, errors: {} });
  };

  // handleNdprAcceptanceCheck = (canLogin) => {
  //   this.setState({ ...this.state, canLogin });
  // };

  componentDidMount() {
    const { cookies } = this.props;
    // this.handleNdprAcceptanceCheck(isCompliant(cookies.get(cookieName)));
  }

  visitCompanyLink = () =>{
    window.location.href =  this.state.urlList[this.state.companyLink];
  }

  handleVisitedLink = (e) =>{
    this.setState({ companyLink: e.target.value})
  }

  render() {
    const { redirectToReferrer, actions, /* data, */ errors, canLogin } = this.state;
    const { isLoggingIn, isShowPassword } = actions;

    /**
      to redirect when login successful
      if would redirect to the private route user tried to access
      if none, it hits the first route in the app which should be dashboard
    **/
    let fromWhere = null;
    if (history.location && (history.location.pathname === ROUTE_PATHS["quick-energy-signin"])) {
      fromWhere = { pathname: ROUTE_PATHS.delivery };
    }
    else if (history.location && (history.location.pathname === ROUTE_PATHS["contract-management"])) {
      fromWhere = { pathname:ROUTE_PATHS.contract };
    }
    if (redirectToReferrer === true && fromWhere !== null) {
           return <Navigate push to={fromWhere} />;
    }
    return (
      <div className="">
        <Header history={history} onAcceptanceCheck={()=>{}} />
        <div className="body">
          <div className="body-container">
            {/* {canLogin && <div className="registration-info">
              <span className="registration-text">New to Smartfuel ?</span>
              <span className="registration-btn">
                  <a href={`${process.env.REACT_APP_FRONT_CUSTOMER_URL || ".#"}/customer/signup`} 
                    style={{color: "#00425F", fontWeight: "bold"}}>Sign Up</a>
              </span>
            </div>} */}

            <div className="login-info">
              <div className="info-div">
                <div className="info-header">
                  {"Payments, loyalty, forecourt management and monitoring"}
                </div>

                <div className="info-body">
                  Your all in one platform for seamless purchase of fuel.
                </div>
              </div>
            </div>
            <div className="login-banner"></div>
            <div className="login-panel">
              {window.location.pathname !== ROUTE_PATHS["forecourt-signin"] && 
              <Fragment>
                <span className="login-title">Login to Dashboard</span>
                <span className="login-instruction">&nbsp;{/* Enter credentials */}</span>
                <div className="input-container">
                  {this.renderAuthInput(
                    "Email Address / Username",
                    "email",
                    false
                  )}
                </div>
                <div className="input-container">
                  {this.renderAuthInput(
                    "Password",
                    "password",
                     false,
                    isShowPassword
                  )}
                  <span
                    onClick={() =>
                      this.setState({
                        ...this.state,
                        actions: { isShowPassword: !isShowPassword },
                      })
                    }
                    style={{
                      position: "absolute",
                      right: 45,
                      marginTop: 7,
                      cursor: "pointer",
                    }}
                  >
                    {isShowPassword ? <EyeOpenSVG /> : <EyeClosedSVG />}
                  </span>
                </div>

                <div className="button-container" style={{ marginTop: 33 }}>
                  <button
                    type="button"
                    onClick={this.onLogin}
                    // ref={submitBtnRef}
                    disabled={isLoggingIn}
                    className="account-btn text-center"
                    value="Login"
                  >
                    {isLoggingIn ? (
                      <img
                        src="/images/ActivityIndicator.gif"
                        alt=""
                        style={{ height: 20, width: 20 }}
                      />
                    ) : (
                      "Login"
                    )}
                  </button>
                </div>
                <a
                className="forgot-password-text"
                style={{ cursor: "pointer" }}
                href={forgotPasswordUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                Forgot Password ?
              </a>
              </Fragment>}
              {window.location.pathname === ROUTE_PATHS["forecourt-signin"] && 
                <Fragment>
                  <div className="row" style={{ marginTop: "30%"}}>
                    <div className="col-12">
                      <SelectInput
                        name="Company"
                        id="company"
                        placeholder="Select company"
                        options={this.state.companyList}
                        value={this.state.companyLink}
                        Onchange={this.handleVisitedLink}
                      />
                    </div>
                  </div>
                  <div className="button-container" style={{ marginTop: 33 }}>
                    <button
                      type="button"
                      onClick={this.visitCompanyLink}
                      // ref={submitBtnRef}
                      // disabled={!companyLink}
                      className="account-btn text-center"
                      value="Continue"
                    >
                      Continue
                    </button>
                  </div>
                </Fragment>}
              
              {(errors.password || errors.email || errors.others) && (
                <div className="error-container">
                  <span className="error-close" onClick={this.dismissError}>
                    x
                  </span>
                  <span className="error-text">
                    {errors.email || errors.password || errors.others}
                  </span>
                </div>
              )}

              <div className="panel-footer-container text-center">
                <span className="panel-footer-text">Need help ? </span><span className="panel-footer-link">Contact Us</span>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { login, data, loginErrorMessage, modules, decodedData } = state.login_reducer;
  return {
    login,
    data,
    loginErrorMessage,
    modules,
    decodedData
  };
};
export { Login as unconnectedLogin };
export default connect(mapStateToProps)(withCookies(Login));
