// generic reducer for entire app
import loginUser from "./login_action";
import onInit from "./init_action";
import logoutUser from "./logout_action";
import reset from "./reset_action";
import toggleSidebar from "./toggleSidebar_action";
import toggleRightSidebar from "./toggleRightSidebar_action";
import sendLeadForm, { clearLeadForm } from "./leadForm_action";

import resetPW from "./pwReset_action";
import forgotPW from "./forgotPW_action";
import changePW from "./changePW_action";

// handling users on platform
import bulkUpload from "./bulkUpload_action";
import createCustomer from "./createCustomer_action";
import editBasicInfo from "./editBasicInfo_action";
import getBasicInfo from "./getBasicInfo_action";
import createVendor from "./createVendor_action";
import createSystemUser from "./createSystemUser_action";
import getSystemUsers from "./getSystemUsers_action";
import getAuditLogs, { downloadAuditLog } from "./getAuditLogs_action";
import getReportLogsActions from "./getReportLogs_action";

// USER status
import toggleUserStatus from "./status/toggleUserStatus_action";
import deleteUser from "./status/deleteUser_action";

// stats
import getISWOrderStats from "./statistics/getISWOrderStats_action";
import getISWProfileStats from "./statistics/getISWProfileStats_action";
import getAllUsers from "./getAllUsers_action.js";
import getCustomerInfo from "./getCustomerInfo_action";
import getCustomerUsers from "./getCustomerUsers_action";
import getCustomers from "./getCustomers_action";
import getVendors from "./getVendors_action";
import getFleets from "./getFleets_action";
import getVendorInfo from "./getVendorInfo_action";
import getFleetInfo from "./getFleetInfo_action";
import getFleetUsers from "./getFleetUsers_action";
import getVendorUsers from "./getVendorUsers_action";

const uiStop = () => ({
    type: "STOP_LOAD"
});

const uiStart = () => ({
    type: "LOAD"
});
const miniUiStart = () => ({
    type: "mini_LOAD"
});
const miniUiStop = () => ({
    type: "mini_STOP_LOAD"
});

export const Actions = {
    getFleetInfo,
    getVendorInfo,
    getCustomerInfo,
    getFleetUsers,
    getVendorUsers,
    getCustomerUsers,
    getCustomers,
    getVendors,
    getFleets,

    ///
    uiStop,
    uiStart,
    miniUiStop,
    miniUiStart,
    getAllUsers,

    toggleSidebar,

    toggleRightSidebar,

    loginUser,

    onInit,

    logoutUser,

    resetPW,

    forgotPW,

    changePW,
    sendLeadForm,
    clearLeadForm,
    
    createCustomer,
    createVendor,

    editBasicInfo,

    getBasicInfo,

    bulkUpload,
    createSystemUser,
    getSystemUsers,

    // GET
    getAuditLogs,
    downloadAuditLog,
    getReportLogsActions,

    // statistics
    getISWOrderStats,
    getISWProfileStats,

    // STATUSES
    deleteUser,
    toggleUserStatus,
    // action to reset actions after every error response
    reset
};
